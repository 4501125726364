import { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';

function ControlledCarousel() {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex) => {
        setIndex(Number(selectedIndex));
    };

    return (
        <>
            <div>
                <Carousel activeIndex={index} onSelect={handleSelect}>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require("../Assets/Images/image 1.png")}
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require("../Assets/Images/image 2.png")}
                            alt="Second slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={require("../Assets/Images/image 3.png")}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                </Carousel>

            </div>
            <div>
                <div className='Button-holder-main'>
                    <div className='Button-holder-Inner'>
                        <button className={`Button-Slider-Controller ${index==0 ? 'highlighted':""}`} id='0' onClick={(e) => handleSelect(e.target.id)}>SEND</button>
                        <button className={`Button-Slider-Controller ${index==1 ? 'highlighted':""}`} id='1' onClick={(e) => handleSelect(e.target.id)}>MANAGE</button>
                        <button className={`Button-Slider-Controller ${index==2 ? 'highlighted':""}`} id='2' onClick={(e) => handleSelect(e.target.id)}>ONBOARD</button>

                    </div>
                </div>
            </div>
        </>
    );
}

export default ControlledCarousel;