import LandingPage from "./Pages/LandingPage";
import './App.css'
<link rel="stylesheet" href="../src/Assets/StyleCopy/a.css"></link>
function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
